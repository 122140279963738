;(function($){

    function Tabs()
    {

        $(".tabs-links a").on("click", function(e){
            e.preventDefault();


            $(".tab-item.active").slick('unslick');

            if ($($(this).attr('href')).hasClass('hidden')) {
                $(".tabs-links a").removeClass('active');
                $(".tab-item").addClass('hidden');
                $(".tab-item").removeClass('active');
                $($(this).attr('href')).removeClass('hidden');
                $($(this).attr('href')).addClass('active');
                $(this).addClass('active');
            }

            $($(this).attr('href')).slick({
              dots: true,
              infinite: true,
              speed: 500,
              arrows: false,
              adaptiveHeight: true,
            });

        });

        $(".tab-item.active").slick({
          dots: true,
          infinite: true,
          speed: 500,
          arrows: false,
          adaptiveHeight: true,
        });       
    }

   	new Tabs();

}(jQuery));
 
 
 
 
