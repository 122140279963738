;(function($){

    function Inscricao() {
        var _ = this;

        var $myForm = $("#newsletter-form").validate({
            rules: {
                email: { required: true, email: true },
            },
            messages: {
                email: { required: 'Informe o seu email', email: 'Ops, informe um email válido' },

            },
            invalidHandler: function(e) {
                swal({
                    title: "OPS! Você não preencheu o campo devidamente!",
                    text: "Preencha o campo e-mail.",
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "OK!",
                    closeOnConfirm: true
                });

            },
            submitHandler: function( form ) {

                $("#newsletter-form .btn-send").html("Enviando...");
                
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: $("#app_url").val() + "/api/newsletter-signup",
                    data: $( form ).serialize(),
                    success: function(result)
                    {

                       if(result.message == "Este e-mail já está cadastrado"){
                        swal("Atenção!", "O email informado já foi cadastrado na newsletter.", "error");

                       }else{

                       swal("Inscrição realizada com sucesso!", "Seu email foi cadastrado na nossa newsletter.", "success");
                       form.reset();
                       }
                    },
                    error: function(response) {
                        console.log(response);
                        swal("Atenção!", "Não conseguimos realizar a inscrição do seu e-mail, por favor tente mais tarde.", "error")
                    }
                });
            }
        }); 
    }

    new Inscricao();

}(jQuery));
 
 