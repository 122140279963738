;(function($){

    function Slides() {
   	 	var _ =  this;

   	 	$('.slider').slick({
		  dots: true,
		  infinite: true,
		  speed: 500,
		  fade: true,
		  autoplay: true,
 		  autoplaySpeed: 3500,
		  cssEase: 'linear',
		  arrows: false,
		});

		$('.slide-full').slick({
		  dots: false,
		  infinite: true,
		  speed: 500,
		  fade: true,
		  cssEase: 'linear',
		  arrows: false,
		  adaptiveHeight: true,
		  asNavFor: '.slide-tumbs',
		});

		$('.slide-tumbs').slick({
		  dots: false,
		  slidesToShow: 3,
		  focusOnSelect: true,
		  asNavFor: '.slide-full',
		});
    }
    
   	new Slides();

}(jQuery));
 
 
 
 
