;(function($){

    function Maps() {

        var _            = this;   

        var cordenates    = {
            lat: -15.677451,
            lng: -56.117780
        };

        _.initMap(cordenates);     
    }

    Maps.prototype.initMap =  function(startingPoint, endPoint) {

        var _ = this;

        var map = new google.maps.Map(document.getElementById('mapa'), {
          center: startingPoint,
          scrollwheel: false,
          zoom: 17
        });

        var directionsDisplay = new google.maps.DirectionsRenderer({
          map: map
        });

        new google.maps.Marker({
          map: map,
          position: startingPoint,
          title: 'Vitta Papéis',
          icon: $("#app_url").val() + "/public/img/icon-location.png",
          animation: google.maps.Animation.DROP
        });
    };

   	new Maps();

}(jQuery));

 
 
 
 